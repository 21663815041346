import React from "react";

const MammogramsInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-xl text-gray-800 mb-2">Your Breasts</p>
        <p className="text-md text-gray-600 mb-12">
          Your breasts are made up of glands, fat, and thickened (fibrous)
          tissue. They respond to changes in levels of the hormones estrogen and
          progesterone during your monthly menstrual cycle. Hormones change the
          amount of fluid in the breast. This may make fibrous areas in the
          breast more painful.
        </p>

        <p className="text-xl text-gray-800 mb-2">What Is Mammography?</p>
        <p className="text-md text-gray-600 mb-2">
          Mammography is a simple X-ray process. It passes low doses of X-rays
          through the breasts. No dyes have to be injected or swallowed, and no
          instruments will be put in your body.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Some growths are very small or lie deep in the breast tissue. These
          growths can be hard to detect. Some growths are benign (not cancer);
          others may be malignant (cancer). Mammography is a good way to find
          cancerous growths before they are large enough to be felt. When cancer
          is found in this early stage, it is easier to treat. Caught early
          enough, breast cancer often can be cured.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Mammography also is useful for checking growths that have been felt
          during a physical exam by a doctor or a breast self-exam. It can be
          done in a doctor’s office, a clinic, a mobile screening van, or a
          hospital. Your doctor can order the test. It is done by an X-ray
          technician trained in mammography. The results then are read by a
          specially trained doctor (radiologist). No matter where the test is
          done, you should get a report of the results from the radiologist or
          your doctor. Ask your doctor about anything you do not understand.
        </p>

        <p className="text-xl text-gray-800 mb-2">Risk Factors</p>
        <p className="text-md text-gray-600 mb-2">
          Most women who get breast cancer have no risk factors except age—as a
          woman gets older, her risk increases (up to age 80 years). However, if
          a woman has any of the following risk factors, she may be at increased
          risk for developing breast cancer:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Certain genes (BRCA1 and BRCA2) passed on from her parents</li>
          <li>Breast cancer in her mother, daughter, or sister</li>
          <li>
            No term pregnancies or pregnancy later in life (age 30 years or
            older)
          </li>
          <li>Early menstruation (younger than age 12 years)</li>
          <li>Late menopause (age 55 years or older)</li>
          <li>Never breastfed a child</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          These factors also may increase the risk of breast cancer for some
          women:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            Personal history of cancer of the breast, endometrium, ovary, or
            colon
          </li>
          <li>Postmenopausal obesity</li>
          <li>Alcohol intake</li>
          <li>Recent hormone therapy</li>
          <li>Recent use of birth control pills</li>
          <li>Tall stature</li>
          <li>Jewish heritage</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          Women aged 40–49 years should have mammography done every 1–2 years.
          Women aged 50 years and older should have it done every year. If you
          have certain risk factors, your doctor may suggest you have the test
          at a younger age.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Who Should Have Mammography?
        </p>
        <p className="text-md text-gray-600 mb-2">
          You also may need mammography if you have any of these signs:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Unexplained lump or thickening in the breast or in the armpit</li>
          <li>
            Puckers, dimples, redness, or other changes in the skin of the
            breast
          </li>
          <li>Discharge or bleeding that comes from the nipple</li>
          <li>
            A recent change in the nipple, such as a retracted nipple (a nipple
            that has pulled inward)
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          If any of these signs apply to you, talk to your doctor about having a
          physical exam and mammography.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Mammography is vital for all women, especially women aged 40 years and
          older. The size of your breasts does not matter. Whether you have
          breast implants does not matter. Women who have had breast cancer
          surgery also may need mammography and other tests to check any breast
          tissue that remains. An ultrasound exam may be done first in younger
          women who are found to have a lump in their breasts.
        </p>

        <p className="text-xl text-gray-800 mb-2">What To Expect</p>
        <p className="text-md text-gray-600 mb-2">
          The day you have the test done, you should not wear powders, lotions,
          or deodorants. This is because most of these products have substances
          that can show on the X-ray films. They can make the films hard for the
          radiologist to read.
        </p>
        <p className="text-md text-gray-600 mb-2">
          To get ready for the test, you will need to undress from the waist up
          and put on a gown. You will be asked to stand or sit in front of the
          X-ray machine. Two smooth, flat plastic or glass plates will be placed
          around one of your breasts. You will briefly feel pressure on your
          breast. The plates will flatten your breast as much as possible so
          that the most tissue can be viewed with the least radiation. After the
          first X-ray, the plates may be removed so that the breast can be
          X-rayed from one or more other positions. The test then is done on the
          other breast.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The pressure of the plates may make the breasts ache. This discomfort
          will go away shortly. If you menstruate, you may want to have the test
          done in the week right after your period. The breasts often are less
          tender at this time.
        </p>
        <p className="text-md text-gray-600 mb-12">
          If you have breast implants, tell your doctor. You also should mention
          your implants to the person who is giving the test. Breast implants
          can make it more difficult to see certain parts of the breast tissue.
          There is some risk that the implant may burst during the test.
          Therefore, extra care should be taken when the breast is compressed.
        </p>

        <p className="text-xl text-gray-800 mb-2">Are There Any Risks?</p>
        <p className="text-md text-gray-600 mb-2">
          Mammography exposes a woman to a very low dose of X-rays. The dose is
          much lower than the natural level of radiation received from the
          environment during a 1-year period. In the past, there was some
          concern about the amount of radiation a woman would be exposed to
          during the test. Improved equipment and techniques now result in very
          low doses. Thus, risk is very low, even with repeated tests. If
          needed, mammography can be done during pregnancy.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Some types of cancer cannot be seen on a mammogram. Even lumps that
          can be felt may not show up. The combination of mammography, regular
          breast exams by your doctor, and self-exam may give the best results.
          If you feel a lump during a breast self-exam, see your doctor.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What If the Test Result Is Positive?
        </p>
        <p className="text-md text-gray-600 mb-12">
          Most lumps found in the breast are benign—not cancer. To confirm the
          results of mammography, other imaging tests, such as ultrasonography
          and magnetic resonance imaging, also may be useful. Magnetic resonance
          imaging of the breast is a method used to view tissue inside the
          breast by using a strong magnetic field and radio waves. With
          ultrasonography, sound waves are used to create pictures of the inside
          of body organs or tissues, such as the breast. This method can tell
          your doctor about certain types of breast lumps. It can show whether
          the lumps are solid or filled with fluid, such as with a cyst. You
          also may need to have the test again if the results of the first test
          were not clear.
        </p>

        <p className="text-xl text-gray-800 mb-2">Finally…</p>
        <p className="text-md text-gray-600 mb-12">
          Combined with regular checkups and breast self-exams, mammography is a
          good way to find cancer at an early and more curable stage. It has
          large benefits and small risks. For women aged 40 years and older,
          mammography should be part of your routine health care.
        </p>
      </div>
    </div>
  );
};

export default MammogramsInfo;
